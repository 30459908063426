<template>
  <div class="ltl-list">
    <div class="ltl-cont">
      <div class="address-menu">
        <a-menu mode="horizontal" @menu-item-click="clickMenu" :selected-keys="[navselect]">
          <a-menu-item :key="1">Created</a-menu-item>
          <a-menu-item :key="2">Processing</a-menu-item>
          <a-menu-item :key="3">Quoted</a-menu-item>
          <a-menu-item :key="4">Merchant Confirmed</a-menu-item>
          <a-menu-item :key="5">Delivered</a-menu-item>
          <a-menu-item :key="6">Canceled</a-menu-item>
        </a-menu>
      </div>

      <div class="ltllist">
        <a-space>
          <a-button type="primary" class="normal-btn" size="large" @click="$router.push('/order/ltl/addltl')">+
          </a-button>
          <a-button type="outline" status="warning" size="large">Cancel</a-button>
        </a-space>
        <!-- 表格 -->
        <a-table :columns="listColumns" :data="listData" :row-selection="rowSelection" style="margin-top: 24px;box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;" :pagination="false" :scroll="{ x: 1000, y: tableHeight.value }" :bordered="false">
          <template #action="a">
            <a-button type="text" @click="edit(a)"><img src="../../../assets/images/order/edit.png" alt=""></a-button>
            <a-button type="text" @click="detail(a)"><img src="../../../assets/images/order/detail.png" alt="">
            </a-button>
          </template>
        </a-table>
      </div>
    </div>
  </div>

</template>
<script setup>
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { ref, reactive, onMounted } from 'vue'
const navselect = ref(1)
onMounted(() => {
  // 修改全局滚动条
  const mainCont = document.querySelector('.main-cont')
  mainCont.style = `
   overflow-y:hidden;
  `
})
onBeforeRouteLeave(() => {
  // 离开滚动条该回去
  const mainCont = document.querySelector('.main-cont')
  mainCont.style = `
   overflow-y:auto;
  `
})
// 获取窗口的高度
const tableHeight = reactive({ value: 0 })
tableHeight.value = document.body.clientHeight - 333
onMounted(() => {
  // 改变窗口高度
  window.onresize = () => {
    tableHeight.value = document.body.clientHeight - 333
  }
})
const router = useRouter()
const rowSelection = {
  type: 'checkbox',
  showCheckedAll: true
}
const listColumns = [
  {
    title: 'Created At',
    dataIndex: 'createdate',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Service',
    dataIndex: 'service',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Price',
    dataIndex: 'price',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Sender',
    dataIndex: 'sender',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Recipient',
    dataIndex: 'recipient',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action'
  }
]
const listData = []
for (let i = 0; i < 20; i++) {
  listData.push({
    key: i,
    createdate: `2022-03-${i}`,
    service: 'N/A',
    price: '22.82',
    sender: 'Sinclair Chan',
    recipient: 'Michael Smith'
  })
}
const detail = (data) => {
  console.log(data)
  router.push('/order/ltl/ltldetail')
}
const edit = (msg) => {
  console.log(msg)
  router.push('/order/ltl/editltl')
}
const clickMenu = (nav) => {
  console.log(nav)
  navselect.value = nav
}
</script>
<style scoped lang="less">
.ltl-list {
  width: 1344px;
  margin: 0 auto;

  .ltl-cont {
    margin: 10px 16px 24px 16px;
  }
}

// 导航
.address-menu {
  width: 800px;

  :deep(.arco-menu-light) {
    background-color: #F6FBFF;
  }

  :deep(.arco-menu-light .arco-menu-item) {
    font-weight: 500;
    font-size: 16px;
    color: #86909C;
    background-color: #fff;
    height: 50px;
    line-height: 50px;
    border-radius: 8px;
  }

  :deep(.arco-menu-horizontal .arco-menu-item:not(:first-child)) {
    margin-left: 0;
  }

  :deep(.arco-menu-horizontal .arco-menu-item) {
    padding: 0 16px;
  }

  :deep(.arco-menu-item.arco-menu-selected) {
    color: #1D2129 !important;
    background: #FFF7E3;
    border-radius: 10px;

    &:hover {
      background: #FFF7E3;
    }
  }

  :deep(.arco-menu-selected-label) {
    background-color: transparent;
  }
}

.ltllist {
  margin: 4px 16px 0 16px;
}
</style>
